export const environment = {
  production: true,
  svAPIKey: "KHLBeta321",
  baseUrl: "https://production.public.api.lafka.dev/api/v1",
  googleAppId:
    "734567008925-nvbbrlecot0d1dip8pb7f16anu34i2mp.apps.googleusercontent.com",
  facebookAppId: "1021525404867816",
  firebase: {
    apiKey: "AIzaSyBjp-l6RcyyOTSg-DUECWGUGiZOiyg-pt8",
    authDomain: "storeversekh24-beta-prod.firebaseapp.com",
    projectId: "storeversekh24-beta-prod",
    storageBucket: "storeversekh24-beta-prod.appspot.com",
    messagingSenderId: "872107307044",
    appId: "1:872107307044:web:115b55cfb23bdfd1ada8b1",
    measurementId: "G-CQKH3J73L6",
  },
};
